var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*! Sortable 1.13.0 - MIT | git://github.com/SortableJS/Sortable.git */
!function (t, e) {
  exports = e();
}(exports, function () {
  "use strict";

  function o(t) {
    return (o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    })(t);
  }

  function a() {
    return (a = Object.assign || function (t) {
      for (var e = 1; e < arguments.length; e++) {
        var n = arguments[e];

        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (t[o] = n[o]);
      }

      return t;
    }).apply(this || _global, arguments);
  }

  function I(i) {
    for (var t = 1; t < arguments.length; t++) {
      var r = null != arguments[t] ? arguments[t] : {},
          e = Object.keys(r);
      "function" == typeof Object.getOwnPropertySymbols && (e = e.concat(Object.getOwnPropertySymbols(r).filter(function (t) {
        return Object.getOwnPropertyDescriptor(r, t).enumerable;
      }))), e.forEach(function (t) {
        var e, n, o;
        e = i, o = r[n = t], n in e ? Object.defineProperty(e, n, {
          value: o,
          enumerable: !0,
          configurable: !0,
          writable: !0
        }) : e[n] = o;
      });
    }

    return i;
  }

  function l(t, e) {
    if (null == t) return {};

    var n,
        o,
        i = function (t, e) {
      if (null == t) return {};
      var n,
          o,
          i = {},
          r = Object.keys(t);

      for (o = 0; o < r.length; o++) n = r[o], 0 <= e.indexOf(n) || (i[n] = t[n]);

      return i;
    }(t, e);

    if (Object.getOwnPropertySymbols) {
      var r = Object.getOwnPropertySymbols(t);

      for (o = 0; o < r.length; o++) n = r[o], 0 <= e.indexOf(n) || Object.prototype.propertyIsEnumerable.call(t, n) && (i[n] = t[n]);
    }

    return i;
  }

  function e(t) {
    return function (t) {
      if (Array.isArray(t)) {
        for (var e = 0, n = new Array(t.length); e < t.length; e++) n[e] = t[e];

        return n;
      }
    }(t) || function (t) {
      if (Symbol.iterator in Object(t) || "[object Arguments]" === Object.prototype.toString.call(t)) return Array.from(t);
    }(t) || function () {
      throw new TypeError("Invalid attempt to spread non-iterable instance");
    }();
  }

  function t(t) {
    if ("undefined" != typeof window && window.navigator) return !!navigator.userAgent.match(t);
  }

  var w = t(/(?:Trident.*rv[ :]?11\.|msie|iemobile|Windows Phone)/i),
      E = t(/Edge/i),
      c = t(/firefox/i),
      u = t(/safari/i) && !t(/chrome/i) && !t(/android/i),
      n = t(/iP(ad|od|hone)/i),
      i = t(/chrome/i) && t(/android/i),
      r = {
    capture: !1,
    passive: !1
  };

  function d(t, e, n) {
    t.addEventListener(e, n, !w && r);
  }

  function s(t, e, n) {
    t.removeEventListener(e, n, !w && r);
  }

  function h(t, e) {
    if (e) {
      if (">" === e[0] && (e = e.substring(1)), t) try {
        if (t.matches) return t.matches(e);
        if (t.msMatchesSelector) return t.msMatchesSelector(e);
        if (t.webkitMatchesSelector) return t.webkitMatchesSelector(e);
      } catch (t) {
        return !1;
      }
      return !1;
    }
  }

  function P(t, e, n, o) {
    if (t) {
      n = n || document;

      do {
        if (null != e && (">" === e[0] ? t.parentNode === n && h(t, e) : h(t, e)) || o && t === n) return t;
        if (t === n) break;
      } while (t = (i = t).host && i !== document && i.host.nodeType ? i.host : i.parentNode);
    }

    var i;
    return null;
  }

  var f,
      p = /\s+/g;

  function k(t, e, n) {
    if (t && e) if (t.classList) t.classList[n ? "add" : "remove"](e);else {
      var o = (" " + t.className + " ").replace(p, " ").replace(" " + e + " ", " ");
      t.className = (o + (n ? " " + e : "")).replace(p, " ");
    }
  }

  function R(t, e, n) {
    var o = t && t.style;

    if (o) {
      if (void 0 === n) return document.defaultView && document.defaultView.getComputedStyle ? n = document.defaultView.getComputedStyle(t, "") : t.currentStyle && (n = t.currentStyle), void 0 === e ? n : n[e];
      e in o || -1 !== e.indexOf("webkit") || (e = "-webkit-" + e), o[e] = n + ("string" == typeof n ? "" : "px");
    }
  }

  function v(t, e) {
    var n = "";
    if ("string" == typeof t) n = t;else do {
      var o = R(t, "transform");
      o && "none" !== o && (n = o + " " + n);
    } while (!e && (t = t.parentNode));
    var i = window.DOMMatrix || window.WebKitCSSMatrix || window.CSSMatrix || window.MSCSSMatrix;
    return i && new i(n);
  }

  function g(t, e, n) {
    if (t) {
      var o = t.getElementsByTagName(e),
          i = 0,
          r = o.length;
      if (n) for (; i < r; i++) n(o[i], i);
      return o;
    }

    return [];
  }

  function A() {
    var t = document.scrollingElement;
    return t || document.documentElement;
  }

  function X(t, e, n, o, i) {
    if (t.getBoundingClientRect || t === window) {
      var r, a, l, s, c, u, d;
      if (d = t !== window && t.parentNode && t !== A() ? (a = (r = t.getBoundingClientRect()).top, l = r.left, s = r.bottom, c = r.right, u = r.height, r.width) : (l = a = 0, s = window.innerHeight, c = window.innerWidth, u = window.innerHeight, window.innerWidth), (e || n) && t !== window && (i = i || t.parentNode, !w)) do {
        if (i && i.getBoundingClientRect && ("none" !== R(i, "transform") || n && "static" !== R(i, "position"))) {
          var h = i.getBoundingClientRect();
          a -= h.top + parseInt(R(i, "border-top-width")), l -= h.left + parseInt(R(i, "border-left-width")), s = a + r.height, c = l + r.width;
          break;
        }
      } while (i = i.parentNode);

      if (o && t !== window) {
        var f = v(i || t),
            p = f && f.a,
            g = f && f.d;
        f && (s = (a /= g) + (u /= g), c = (l /= p) + (d /= p));
      }

      return {
        top: a,
        left: l,
        bottom: s,
        right: c,
        width: d,
        height: u
      };
    }
  }

  function Y(t, e, n) {
    for (var o = H(t, !0), i = X(t)[e]; o;) {
      var r = X(o)[n];
      if (!("top" === n || "left" === n ? r <= i : i <= r)) return o;
      if (o === A()) break;
      o = H(o, !1);
    }

    return !1;
  }

  function m(t, e, n) {
    for (var o = 0, i = 0, r = t.children; i < r.length;) {
      if ("none" !== r[i].style.display && r[i] !== Rt.ghost && r[i] !== Rt.dragged && P(r[i], n.draggable, t, !1)) {
        if (o === e) return r[i];
        o++;
      }

      i++;
    }

    return null;
  }

  function B(t, e) {
    for (var n = t.lastElementChild; n && (n === Rt.ghost || "none" === R(n, "display") || e && !h(n, e));) n = n.previousElementSibling;

    return n || null;
  }

  function F(t, e) {
    var n = 0;
    if (!t || !t.parentNode) return -1;

    for (; t = t.previousElementSibling;) "TEMPLATE" === t.nodeName.toUpperCase() || t === Rt.clone || e && !h(t, e) || n++;

    return n;
  }

  function b(t) {
    var e = 0,
        n = 0,
        o = A();
    if (t) do {
      var i = v(t),
          r = i.a,
          a = i.d;
      e += t.scrollLeft * r, n += t.scrollTop * a;
    } while (t !== o && (t = t.parentNode));
    return [e, n];
  }

  function H(t, e) {
    if (!t || !t.getBoundingClientRect) return A();
    var n = t,
        o = !1;

    do {
      if (n.clientWidth < n.scrollWidth || n.clientHeight < n.scrollHeight) {
        var i = R(n);

        if (n.clientWidth < n.scrollWidth && ("auto" == i.overflowX || "scroll" == i.overflowX) || n.clientHeight < n.scrollHeight && ("auto" == i.overflowY || "scroll" == i.overflowY)) {
          if (!n.getBoundingClientRect || n === document.body) return A();
          if (o || e) return n;
          o = !0;
        }
      }
    } while (n = n.parentNode);

    return A();
  }

  function y(t, e) {
    return Math.round(t.top) === Math.round(e.top) && Math.round(t.left) === Math.round(e.left) && Math.round(t.height) === Math.round(e.height) && Math.round(t.width) === Math.round(e.width);
  }

  function D(e, n) {
    return function () {
      if (!f) {
        var t = arguments;
        1 === t.length ? e.call(this || _global, t[0]) : e.apply(this || _global, t), f = setTimeout(function () {
          f = void 0;
        }, n);
      }
    };
  }

  function L(t, e, n) {
    t.scrollLeft += e, t.scrollTop += n;
  }

  function S(t) {
    var e = window.Polymer,
        n = window.jQuery || window.Zepto;
    return e && e.dom ? e.dom(t).cloneNode(!0) : n ? n(t).clone(!0)[0] : t.cloneNode(!0);
  }

  function _(t, e) {
    R(t, "position", "absolute"), R(t, "top", e.top), R(t, "left", e.left), R(t, "width", e.width), R(t, "height", e.height);
  }

  function C(t) {
    R(t, "position", ""), R(t, "top", ""), R(t, "left", ""), R(t, "width", ""), R(t, "height", "");
  }

  var j = "Sortable" + new Date().getTime();

  function T() {
    var e,
        o = [];
    return {
      captureAnimationState: function () {
        o = [], (this || _global).options.animation && [].slice.call((this || _global).el.children).forEach(function (t) {
          if ("none" !== R(t, "display") && t !== Rt.ghost) {
            o.push({
              target: t,
              rect: X(t)
            });
            var e = I({}, o[o.length - 1].rect);

            if (t.thisAnimationDuration) {
              var n = v(t, !0);
              n && (e.top -= n.f, e.left -= n.e);
            }

            t.fromRect = e;
          }
        });
      },
      addAnimationState: function (t) {
        o.push(t);
      },
      removeAnimationState: function (t) {
        o.splice(function (t, e) {
          for (var n in t) if (t.hasOwnProperty(n)) for (var o in e) if (e.hasOwnProperty(o) && e[o] === t[n][o]) return Number(n);

          return -1;
        }(o, {
          target: t
        }), 1);
      },
      animateAll: function (t) {
        var c = this || _global;
        if (!(this || _global).options.animation) return clearTimeout(e), void ("function" == typeof t && t());
        var u = !1,
            d = 0;
        o.forEach(function (t) {
          var e = 0,
              n = t.target,
              o = n.fromRect,
              i = X(n),
              r = n.prevFromRect,
              a = n.prevToRect,
              l = t.rect,
              s = v(n, !0);
          s && (i.top -= s.f, i.left -= s.e), n.toRect = i, n.thisAnimationDuration && y(r, i) && !y(o, i) && (l.top - i.top) / (l.left - i.left) == (o.top - i.top) / (o.left - i.left) && (e = function (t, e, n, o) {
            return Math.sqrt(Math.pow(e.top - t.top, 2) + Math.pow(e.left - t.left, 2)) / Math.sqrt(Math.pow(e.top - n.top, 2) + Math.pow(e.left - n.left, 2)) * o.animation;
          }(l, r, a, c.options)), y(i, o) || (n.prevFromRect = o, n.prevToRect = i, e || (e = c.options.animation), c.animate(n, l, i, e)), e && (u = !0, d = Math.max(d, e), clearTimeout(n.animationResetTimer), n.animationResetTimer = setTimeout(function () {
            n.animationTime = 0, n.prevFromRect = null, n.fromRect = null, n.prevToRect = null, n.thisAnimationDuration = null;
          }, e), n.thisAnimationDuration = e);
        }), clearTimeout(e), u ? e = setTimeout(function () {
          "function" == typeof t && t();
        }, d) : "function" == typeof t && t(), o = [];
      },
      animate: function (t, e, n, o) {
        if (o) {
          R(t, "transition", ""), R(t, "transform", "");
          var i = v((this || _global).el),
              r = i && i.a,
              a = i && i.d,
              l = (e.left - n.left) / (r || 1),
              s = (e.top - n.top) / (a || 1);
          t.animatingX = !!l, t.animatingY = !!s, R(t, "transform", "translate3d(" + l + "px," + s + "px,0)"), (this || _global).forRepaintDummy = function (t) {
            return t.offsetWidth;
          }(t), R(t, "transition", "transform " + o + "ms" + ((this || _global).options.easing ? " " + (this || _global).options.easing : "")), R(t, "transform", "translate3d(0,0,0)"), "number" == typeof t.animated && clearTimeout(t.animated), t.animated = setTimeout(function () {
            R(t, "transition", ""), R(t, "transform", ""), t.animated = !1, t.animatingX = !1, t.animatingY = !1;
          }, o);
        }
      }
    };
  }

  var x = [],
      M = {
    initializeByDefault: !0
  },
      O = {
    mount: function (e) {
      for (var t in M) !M.hasOwnProperty(t) || t in e || (e[t] = M[t]);

      x.forEach(function (t) {
        if (t.pluginName === e.pluginName) throw "Sortable: Cannot mount plugin ".concat(e.pluginName, " more than once");
      }), x.push(e);
    },
    pluginEvent: function (e, n, o) {
      var t = this || _global;
      (this || _global).eventCanceled = !1, o.cancel = function () {
        t.eventCanceled = !0;
      };
      var i = e + "Global";
      x.forEach(function (t) {
        n[t.pluginName] && (n[t.pluginName][i] && n[t.pluginName][i](I({
          sortable: n
        }, o)), n.options[t.pluginName] && n[t.pluginName][e] && n[t.pluginName][e](I({
          sortable: n
        }, o)));
      });
    },
    initializePlugins: function (o, i, r, t) {
      for (var e in x.forEach(function (t) {
        var e = t.pluginName;

        if (o.options[e] || t.initializeByDefault) {
          var n = new t(o, i, o.options);
          n.sortable = o, n.options = o.options, o[e] = n, a(r, n.defaults);
        }
      }), o.options) if (o.options.hasOwnProperty(e)) {
        var n = this.modifyOption(o, e, o.options[e]);
        void 0 !== n && (o.options[e] = n);
      }
    },
    getEventProperties: function (e, n) {
      var o = {};
      return x.forEach(function (t) {
        "function" == typeof t.eventProperties && a(o, t.eventProperties.call(n[t.pluginName], e));
      }), o;
    },
    modifyOption: function (e, n, o) {
      var i;
      return x.forEach(function (t) {
        e[t.pluginName] && t.optionListeners && "function" == typeof t.optionListeners[n] && (i = t.optionListeners[n].call(e[t.pluginName], o));
      }), i;
    }
  };

  function N(t) {
    var e = t.sortable,
        n = t.rootEl,
        o = t.name,
        i = t.targetEl,
        r = t.cloneEl,
        a = t.toEl,
        l = t.fromEl,
        s = t.oldIndex,
        c = t.newIndex,
        u = t.oldDraggableIndex,
        d = t.newDraggableIndex,
        h = t.originalEvent,
        f = t.putSortable,
        p = t.extraEventProperties;

    if (e = e || n && n[j]) {
      var g,
          v = e.options,
          m = "on" + o.charAt(0).toUpperCase() + o.substr(1);
      !window.CustomEvent || w || E ? (g = document.createEvent("Event")).initEvent(o, !0, !0) : g = new CustomEvent(o, {
        bubbles: !0,
        cancelable: !0
      }), g.to = a || n, g.from = l || n, g.item = i || n, g.clone = r, g.oldIndex = s, g.newIndex = c, g.oldDraggableIndex = u, g.newDraggableIndex = d, g.originalEvent = h, g.pullMode = f ? f.lastPutMode : void 0;
      var b = I({}, p, O.getEventProperties(o, e));

      for (var y in b) g[y] = b[y];

      n && n.dispatchEvent(g), v[m] && v[m].call(e, g);
    }
  }

  function K(t, e, n) {
    var o = 2 < arguments.length && void 0 !== n ? n : {},
        i = o.evt,
        r = l(o, ["evt"]);
    O.pluginEvent.bind(Rt)(t, e, I({
      dragEl: z,
      parentEl: G,
      ghostEl: U,
      rootEl: q,
      nextEl: V,
      lastDownEl: Z,
      cloneEl: Q,
      cloneHidden: $,
      dragStarted: dt,
      putSortable: it,
      activeSortable: Rt.active,
      originalEvent: i,
      oldIndex: J,
      oldDraggableIndex: et,
      newIndex: tt,
      newDraggableIndex: nt,
      hideGhostForTarget: At,
      unhideGhostForTarget: It,
      cloneNowHidden: function () {
        $ = !0;
      },
      cloneNowShown: function () {
        $ = !1;
      },
      dispatchSortableEvent: function (t) {
        W({
          sortable: e,
          name: t,
          originalEvent: i
        });
      }
    }, r));
  }

  function W(t) {
    N(I({
      putSortable: it,
      cloneEl: Q,
      targetEl: z,
      rootEl: q,
      oldIndex: J,
      oldDraggableIndex: et,
      newIndex: tt,
      newDraggableIndex: nt
    }, t));
  }

  var z,
      G,
      U,
      q,
      V,
      Z,
      Q,
      $,
      J,
      tt,
      et,
      nt,
      ot,
      it,
      rt,
      at,
      lt,
      st,
      ct,
      ut,
      dt,
      ht,
      ft,
      pt,
      gt,
      vt = !1,
      mt = !1,
      bt = [],
      yt = !1,
      wt = !1,
      Et = [],
      Dt = !1,
      St = [],
      _t = "undefined" != typeof document,
      Ct = n,
      Tt = E || w ? "cssFloat" : "float",
      xt = _t && !i && !n && "draggable" in document.createElement("div"),
      Mt = function () {
    if (_t) {
      if (w) return !1;
      var t = document.createElement("x");
      return t.style.cssText = "pointer-events:auto", "auto" === t.style.pointerEvents;
    }
  }(),
      Ot = function (t, e) {
    var n = R(t),
        o = parseInt(n.width) - parseInt(n.paddingLeft) - parseInt(n.paddingRight) - parseInt(n.borderLeftWidth) - parseInt(n.borderRightWidth),
        i = m(t, 0, e),
        r = m(t, 1, e),
        a = i && R(i),
        l = r && R(r),
        s = a && parseInt(a.marginLeft) + parseInt(a.marginRight) + X(i).width,
        c = l && parseInt(l.marginLeft) + parseInt(l.marginRight) + X(r).width;
    if ("flex" === n.display) return "column" === n.flexDirection || "column-reverse" === n.flexDirection ? "vertical" : "horizontal";
    if ("grid" === n.display) return n.gridTemplateColumns.split(" ").length <= 1 ? "vertical" : "horizontal";

    if (i && a.float && "none" !== a.float) {
      var u = "left" === a.float ? "left" : "right";
      return !r || "both" !== l.clear && l.clear !== u ? "horizontal" : "vertical";
    }

    return i && ("block" === a.display || "flex" === a.display || "table" === a.display || "grid" === a.display || o <= s && "none" === n[Tt] || r && "none" === n[Tt] && o < s + c) ? "vertical" : "horizontal";
  },
      Nt = function (t) {
    function s(a, l) {
      return function (t, e, n, o) {
        var i = t.options.group.name && e.options.group.name && t.options.group.name === e.options.group.name;
        if (null == a && (l || i)) return !0;
        if (null == a || !1 === a) return !1;
        if (l && "clone" === a) return a;
        if ("function" == typeof a) return s(a(t, e, n, o), l)(t, e, n, o);
        var r = (l ? t : e).options.group.name;
        return !0 === a || "string" == typeof a && a === r || a.join && -1 < a.indexOf(r);
      };
    }

    var e = {},
        n = t.group;
    n && "object" == o(n) || (n = {
      name: n
    }), e.name = n.name, e.checkPull = s(n.pull, !0), e.checkPut = s(n.put), e.revertClone = n.revertClone, t.group = e;
  },
      At = function () {
    !Mt && U && R(U, "display", "none");
  },
      It = function () {
    !Mt && U && R(U, "display", "");
  };

  _t && document.addEventListener("click", function (t) {
    if (mt) return t.preventDefault(), t.stopPropagation && t.stopPropagation(), t.stopImmediatePropagation && t.stopImmediatePropagation(), mt = !1;
  }, !0);

  function Pt(t) {
    if (z) {
      var e = function (r, a) {
        var l;
        return bt.some(function (t) {
          if (!B(t)) {
            var e = X(t),
                n = t[j].options.emptyInsertThreshold,
                o = r >= e.left - n && r <= e.right + n,
                i = a >= e.top - n && a <= e.bottom + n;
            return n && o && i ? l = t : void 0;
          }
        }), l;
      }((t = t.touches ? t.touches[0] : t).clientX, t.clientY);

      if (e) {
        var n = {};

        for (var o in t) t.hasOwnProperty(o) && (n[o] = t[o]);

        n.target = n.rootEl = e, n.preventDefault = void 0, n.stopPropagation = void 0, e[j]._onDragOver(n);
      }
    }
  }

  function kt(t) {
    z && z.parentNode[j]._isOutsideThisEl(t.target);
  }

  function Rt(t, e) {
    if (!t || !t.nodeType || 1 !== t.nodeType) throw "Sortable: `el` must be an HTMLElement, not ".concat({}.toString.call(t));
    (this || _global).el = t, (this || _global).options = e = a({}, e), t[j] = this || _global;
    var n = {
      group: null,
      sort: !0,
      disabled: !1,
      store: null,
      handle: null,
      draggable: /^[uo]l$/i.test(t.nodeName) ? ">li" : ">*",
      swapThreshold: 1,
      invertSwap: !1,
      invertedSwapThreshold: null,
      removeCloneOnHide: !0,
      direction: function () {
        return Ot(t, (this || _global).options);
      },
      ghostClass: "sortable-ghost",
      chosenClass: "sortable-chosen",
      dragClass: "sortable-drag",
      ignore: "a, img",
      filter: null,
      preventOnFilter: !0,
      animation: 0,
      easing: null,
      setData: function (t, e) {
        t.setData("Text", e.textContent);
      },
      dropBubble: !1,
      dragoverBubble: !1,
      dataIdAttr: "data-id",
      delay: 0,
      delayOnTouchOnly: !1,
      touchStartThreshold: (Number.parseInt ? Number : window).parseInt(window.devicePixelRatio, 10) || 1,
      forceFallback: !1,
      fallbackClass: "sortable-fallback",
      fallbackOnBody: !1,
      fallbackTolerance: 0,
      fallbackOffset: {
        x: 0,
        y: 0
      },
      supportPointer: !1 !== Rt.supportPointer && "PointerEvent" in window && !u,
      emptyInsertThreshold: 5
    };

    for (var o in O.initializePlugins(this || _global, t, n), n) o in e || (e[o] = n[o]);

    for (var i in Nt(e), this || _global) "_" === i.charAt(0) && "function" == typeof (this || _global)[i] && ((this || _global)[i] = (this || _global)[i].bind(this || _global));

    (this || _global).nativeDraggable = !e.forceFallback && xt, (this || _global).nativeDraggable && ((this || _global).options.touchStartThreshold = 1), e.supportPointer ? d(t, "pointerdown", (this || _global)._onTapStart) : (d(t, "mousedown", (this || _global)._onTapStart), d(t, "touchstart", (this || _global)._onTapStart)), (this || _global).nativeDraggable && (d(t, "dragover", this || _global), d(t, "dragenter", this || _global)), bt.push((this || _global).el), e.store && e.store.get && this.sort(e.store.get(this || _global) || []), a(this || _global, T());
  }

  function Xt(t, e, n, o, i, r, a, l) {
    var s,
        c,
        u = t[j],
        d = u.options.onMove;
    return !window.CustomEvent || w || E ? (s = document.createEvent("Event")).initEvent("move", !0, !0) : s = new CustomEvent("move", {
      bubbles: !0,
      cancelable: !0
    }), s.to = e, s.from = t, s.dragged = n, s.draggedRect = o, s.related = i || e, s.relatedRect = r || X(e), s.willInsertAfter = l, s.originalEvent = a, t.dispatchEvent(s), d && (c = d.call(u, s, a)), c;
  }

  function Yt(t) {
    t.draggable = !1;
  }

  function Bt() {
    Dt = !1;
  }

  function Ft(t) {
    for (var e = t.tagName + t.className + t.src + t.href + t.textContent, n = e.length, o = 0; n--;) o += e.charCodeAt(n);

    return o.toString(36);
  }

  function Ht(t) {
    return setTimeout(t, 0);
  }

  function Lt(t) {
    return clearTimeout(t);
  }

  Rt.prototype = {
    constructor: Rt,
    _isOutsideThisEl: function (t) {
      (this || _global).el.contains(t) || t === (this || _global).el || (ht = null);
    },
    _getDirection: function (t, e) {
      return "function" == typeof (this || _global).options.direction ? (this || _global).options.direction.call(this || _global, t, e, z) : (this || _global).options.direction;
    },
    _onTapStart: function (e) {
      if (e.cancelable) {
        var n = this || _global,
            o = (this || _global).el,
            t = (this || _global).options,
            i = t.preventOnFilter,
            r = e.type,
            a = e.touches && e.touches[0] || e.pointerType && "touch" === e.pointerType && e,
            l = (a || e).target,
            s = e.target.shadowRoot && (e.path && e.path[0] || e.composedPath && e.composedPath()[0]) || l,
            c = t.filter;

        if (function (t) {
          St.length = 0;
          var e = t.getElementsByTagName("input"),
              n = e.length;

          for (; n--;) {
            var o = e[n];
            o.checked && St.push(o);
          }
        }(o), !z && !(/mousedown|pointerdown/.test(r) && 0 !== e.button || t.disabled) && !s.isContentEditable && ((this || _global).nativeDraggable || !u || !l || "SELECT" !== l.tagName.toUpperCase()) && !((l = P(l, t.draggable, o, !1)) && l.animated || Z === l)) {
          if (J = F(l), et = F(l, t.draggable), "function" == typeof c) {
            if (c.call(this || _global, e, l, this || _global)) return W({
              sortable: n,
              rootEl: s,
              name: "filter",
              targetEl: l,
              toEl: o,
              fromEl: o
            }), K("filter", n, {
              evt: e
            }), void (i && e.cancelable && e.preventDefault());
          } else if (c && (c = c.split(",").some(function (t) {
            if (t = P(s, t.trim(), o, !1)) return W({
              sortable: n,
              rootEl: t,
              name: "filter",
              targetEl: l,
              fromEl: o,
              toEl: o
            }), K("filter", n, {
              evt: e
            }), !0;
          }))) return void (i && e.cancelable && e.preventDefault());

          t.handle && !P(s, t.handle, o, !1) || this._prepareDragStart(e, a, l);
        }
      }
    },
    _prepareDragStart: function (t, e, n) {
      var o,
          i = this || _global,
          r = i.el,
          a = i.options,
          l = r.ownerDocument;

      if (n && !z && n.parentNode === r) {
        var s = X(n);
        if (q = r, G = (z = n).parentNode, V = z.nextSibling, Z = n, ot = a.group, rt = {
          target: Rt.dragged = z,
          clientX: (e || t).clientX,
          clientY: (e || t).clientY
        }, ct = rt.clientX - s.left, ut = rt.clientY - s.top, (this || _global)._lastX = (e || t).clientX, (this || _global)._lastY = (e || t).clientY, z.style["will-change"] = "all", o = function () {
          K("delayEnded", i, {
            evt: t
          }), Rt.eventCanceled ? i._onDrop() : (i._disableDelayedDragEvents(), !c && i.nativeDraggable && (z.draggable = !0), i._triggerDragStart(t, e), W({
            sortable: i,
            name: "choose",
            originalEvent: t
          }), k(z, a.chosenClass, !0));
        }, a.ignore.split(",").forEach(function (t) {
          g(z, t.trim(), Yt);
        }), d(l, "dragover", Pt), d(l, "mousemove", Pt), d(l, "touchmove", Pt), d(l, "mouseup", i._onDrop), d(l, "touchend", i._onDrop), d(l, "touchcancel", i._onDrop), c && (this || _global).nativeDraggable && ((this || _global).options.touchStartThreshold = 4, z.draggable = !0), K("delayStart", this || _global, {
          evt: t
        }), !a.delay || a.delayOnTouchOnly && !e || (this || _global).nativeDraggable && (E || w)) o();else {
          if (Rt.eventCanceled) return void this._onDrop();
          d(l, "mouseup", i._disableDelayedDrag), d(l, "touchend", i._disableDelayedDrag), d(l, "touchcancel", i._disableDelayedDrag), d(l, "mousemove", i._delayedDragTouchMoveHandler), d(l, "touchmove", i._delayedDragTouchMoveHandler), a.supportPointer && d(l, "pointermove", i._delayedDragTouchMoveHandler), i._dragStartTimer = setTimeout(o, a.delay);
        }
      }
    },
    _delayedDragTouchMoveHandler: function (t) {
      var e = t.touches ? t.touches[0] : t;
      Math.max(Math.abs(e.clientX - (this || _global)._lastX), Math.abs(e.clientY - (this || _global)._lastY)) >= Math.floor((this || _global).options.touchStartThreshold / ((this || _global).nativeDraggable && window.devicePixelRatio || 1)) && this._disableDelayedDrag();
    },
    _disableDelayedDrag: function () {
      z && Yt(z), clearTimeout((this || _global)._dragStartTimer), this._disableDelayedDragEvents();
    },
    _disableDelayedDragEvents: function () {
      var t = (this || _global).el.ownerDocument;
      s(t, "mouseup", (this || _global)._disableDelayedDrag), s(t, "touchend", (this || _global)._disableDelayedDrag), s(t, "touchcancel", (this || _global)._disableDelayedDrag), s(t, "mousemove", (this || _global)._delayedDragTouchMoveHandler), s(t, "touchmove", (this || _global)._delayedDragTouchMoveHandler), s(t, "pointermove", (this || _global)._delayedDragTouchMoveHandler);
    },
    _triggerDragStart: function (t, e) {
      e = e || "touch" == t.pointerType && t, !(this || _global).nativeDraggable || e ? (this || _global).options.supportPointer ? d(document, "pointermove", (this || _global)._onTouchMove) : d(document, e ? "touchmove" : "mousemove", (this || _global)._onTouchMove) : (d(z, "dragend", this || _global), d(q, "dragstart", (this || _global)._onDragStart));

      try {
        document.selection ? Ht(function () {
          document.selection.empty();
        }) : window.getSelection().removeAllRanges();
      } catch (t) {}
    },
    _dragStarted: function (t, e) {
      if (vt = !1, q && z) {
        K("dragStarted", this || _global, {
          evt: e
        }), (this || _global).nativeDraggable && d(document, "dragover", kt);
        var n = (this || _global).options;
        t || k(z, n.dragClass, !1), k(z, n.ghostClass, !0), Rt.active = this || _global, t && this._appendGhost(), W({
          sortable: this || _global,
          name: "start",
          originalEvent: e
        });
      } else this._nulling();
    },
    _emulateDragOver: function () {
      if (at) {
        (this || _global)._lastX = at.clientX, (this || _global)._lastY = at.clientY, At();

        for (var t = document.elementFromPoint(at.clientX, at.clientY), e = t; t && t.shadowRoot && (t = t.shadowRoot.elementFromPoint(at.clientX, at.clientY)) !== e;) e = t;

        if (z.parentNode[j]._isOutsideThisEl(t), e) do {
          if (e[j]) {
            if (e[j]._onDragOver({
              clientX: at.clientX,
              clientY: at.clientY,
              target: t,
              rootEl: e
            }) && !(this || _global).options.dragoverBubble) break;
          }

          t = e;
        } while (e = e.parentNode);
        It();
      }
    },
    _onTouchMove: function (t) {
      if (rt) {
        var e = (this || _global).options,
            n = e.fallbackTolerance,
            o = e.fallbackOffset,
            i = t.touches ? t.touches[0] : t,
            r = U && v(U, !0),
            a = U && r && r.a,
            l = U && r && r.d,
            s = Ct && gt && b(gt),
            c = (i.clientX - rt.clientX + o.x) / (a || 1) + (s ? s[0] - Et[0] : 0) / (a || 1),
            u = (i.clientY - rt.clientY + o.y) / (l || 1) + (s ? s[1] - Et[1] : 0) / (l || 1);

        if (!Rt.active && !vt) {
          if (n && Math.max(Math.abs(i.clientX - (this || _global)._lastX), Math.abs(i.clientY - (this || _global)._lastY)) < n) return;

          this._onDragStart(t, !0);
        }

        if (U) {
          r ? (r.e += c - (lt || 0), r.f += u - (st || 0)) : r = {
            a: 1,
            b: 0,
            c: 0,
            d: 1,
            e: c,
            f: u
          };
          var d = "matrix(".concat(r.a, ",").concat(r.b, ",").concat(r.c, ",").concat(r.d, ",").concat(r.e, ",").concat(r.f, ")");
          R(U, "webkitTransform", d), R(U, "mozTransform", d), R(U, "msTransform", d), R(U, "transform", d), lt = c, st = u, at = i;
        }

        t.cancelable && t.preventDefault();
      }
    },
    _appendGhost: function () {
      if (!U) {
        var t = (this || _global).options.fallbackOnBody ? document.body : q,
            e = X(z, !0, Ct, !0, t),
            n = (this || _global).options;

        if (Ct) {
          for (gt = t; "static" === R(gt, "position") && "none" === R(gt, "transform") && gt !== document;) gt = gt.parentNode;

          gt !== document.body && gt !== document.documentElement ? (gt === document && (gt = A()), e.top += gt.scrollTop, e.left += gt.scrollLeft) : gt = A(), Et = b(gt);
        }

        k(U = z.cloneNode(!0), n.ghostClass, !1), k(U, n.fallbackClass, !0), k(U, n.dragClass, !0), R(U, "transition", ""), R(U, "transform", ""), R(U, "box-sizing", "border-box"), R(U, "margin", 0), R(U, "top", e.top), R(U, "left", e.left), R(U, "width", e.width), R(U, "height", e.height), R(U, "opacity", "0.8"), R(U, "position", Ct ? "absolute" : "fixed"), R(U, "zIndex", "100000"), R(U, "pointerEvents", "none"), Rt.ghost = U, t.appendChild(U), R(U, "transform-origin", ct / parseInt(U.style.width) * 100 + "% " + ut / parseInt(U.style.height) * 100 + "%");
      }
    },
    _onDragStart: function (t, e) {
      var n = this || _global,
          o = t.dataTransfer,
          i = n.options;
      K("dragStart", this || _global, {
        evt: t
      }), Rt.eventCanceled ? this._onDrop() : (K("setupClone", this || _global), Rt.eventCanceled || ((Q = S(z)).draggable = !1, Q.style["will-change"] = "", this._hideClone(), k(Q, (this || _global).options.chosenClass, !1), Rt.clone = Q), n.cloneId = Ht(function () {
        K("clone", n), Rt.eventCanceled || (n.options.removeCloneOnHide || q.insertBefore(Q, z), n._hideClone(), W({
          sortable: n,
          name: "clone"
        }));
      }), e || k(z, i.dragClass, !0), e ? (mt = !0, n._loopId = setInterval(n._emulateDragOver, 50)) : (s(document, "mouseup", n._onDrop), s(document, "touchend", n._onDrop), s(document, "touchcancel", n._onDrop), o && (o.effectAllowed = "move", i.setData && i.setData.call(n, o, z)), d(document, "drop", n), R(z, "transform", "translateZ(0)")), vt = !0, n._dragStartId = Ht(n._dragStarted.bind(n, e, t)), d(document, "selectstart", n), dt = !0, u && R(document.body, "user-select", "none"));
    },
    _onDragOver: function (n) {
      var o,
          i,
          r,
          a,
          l = (this || _global).el,
          s = n.target,
          e = (this || _global).options,
          t = e.group,
          c = Rt.active,
          u = ot === t,
          d = e.sort,
          h = it || c,
          f = this || _global,
          p = !1;

      if (!Dt) {
        if (void 0 !== n.preventDefault && n.cancelable && n.preventDefault(), s = P(s, e.draggable, l, !0), M("dragOver"), Rt.eventCanceled) return p;
        if (z.contains(n.target) || s.animated && s.animatingX && s.animatingY || f._ignoreWhileAnimating === s) return N(!1);

        if (mt = !1, c && !e.disabled && (u ? d || (r = !q.contains(z)) : it === (this || _global) || ((this || _global).lastPutMode = ot.checkPull(this || _global, c, z, n)) && t.checkPut(this || _global, c, z, n))) {
          if (a = "vertical" === this._getDirection(n, s), o = X(z), M("dragOverValid"), Rt.eventCanceled) return p;
          if (r) return G = q, O(), this._hideClone(), M("revert"), Rt.eventCanceled || (V ? q.insertBefore(z, V) : q.appendChild(z)), N(!0);
          var g = B(l, e.draggable);

          if (!g || function (t, e, n) {
            var o = X(B(n.el, n.options.draggable));
            return e ? t.clientX > o.right + 10 || t.clientX <= o.right && t.clientY > o.bottom && t.clientX >= o.left : t.clientX > o.right && t.clientY > o.top || t.clientX <= o.right && t.clientY > o.bottom + 10;
          }(n, a, this || _global) && !g.animated) {
            if (g === z) return N(!1);
            if (g && l === n.target && (s = g), s && (i = X(s)), !1 !== Xt(q, l, z, o, s, i, n, !!s)) return O(), l.appendChild(z), G = l, A(), N(!0);
          } else if (s.parentNode === l) {
            i = X(s);
            var v,
                m,
                b,
                y = z.parentNode !== l,
                w = !function (t, e, n) {
              var o = n ? t.left : t.top,
                  i = n ? t.right : t.bottom,
                  r = n ? t.width : t.height,
                  a = n ? e.left : e.top,
                  l = n ? e.right : e.bottom,
                  s = n ? e.width : e.height;
              return o === a || i === l || o + r / 2 === a + s / 2;
            }(z.animated && z.toRect || o, s.animated && s.toRect || i, a),
                E = a ? "top" : "left",
                D = Y(s, "top", "top") || Y(z, "top", "top"),
                S = D ? D.scrollTop : void 0;
            if (ht !== s && (m = i[E], yt = !1, wt = !w && e.invertSwap || y), 0 !== (v = function (t, e, n, o, i, r, a, l) {
              var s = o ? t.clientY : t.clientX,
                  c = o ? n.height : n.width,
                  u = o ? n.top : n.left,
                  d = o ? n.bottom : n.right,
                  h = !1;
              if (!a) if (l && pt < c * i) {
                if (!yt && (1 === ft ? u + c * r / 2 < s : s < d - c * r / 2) && (yt = !0), yt) h = !0;else if (1 === ft ? s < u + pt : d - pt < s) return -ft;
              } else if (u + c * (1 - i) / 2 < s && s < d - c * (1 - i) / 2) return function (t) {
                return F(z) < F(t) ? 1 : -1;
              }(e);
              if ((h = h || a) && (s < u + c * r / 2 || d - c * r / 2 < s)) return u + c / 2 < s ? 1 : -1;
              return 0;
            }(n, s, i, a, w ? 1 : e.swapThreshold, null == e.invertedSwapThreshold ? e.swapThreshold : e.invertedSwapThreshold, wt, ht === s))) for (var _ = F(z); _ -= v, (b = G.children[_]) && ("none" === R(b, "display") || b === U););
            if (0 === v || b === s) return N(!1);
            ft = v;
            var C = (ht = s).nextElementSibling,
                T = !1,
                x = Xt(q, l, z, o, s, i, n, T = 1 === v);
            if (!1 !== x) return 1 !== x && -1 !== x || (T = 1 === x), Dt = !0, setTimeout(Bt, 30), O(), T && !C ? l.appendChild(z) : s.parentNode.insertBefore(z, T ? C : s), D && L(D, 0, S - D.scrollTop), G = z.parentNode, void 0 === m || wt || (pt = Math.abs(m - X(s)[E])), A(), N(!0);
          }

          if (l.contains(z)) return N(!1);
        }

        return !1;
      }

      function M(t, e) {
        K(t, f, I({
          evt: n,
          isOwner: u,
          axis: a ? "vertical" : "horizontal",
          revert: r,
          dragRect: o,
          targetRect: i,
          canSort: d,
          fromSortable: h,
          target: s,
          completed: N,
          onMove: function (t, e) {
            return Xt(q, l, z, o, t, X(t), n, e);
          },
          changed: A
        }, e));
      }

      function O() {
        M("dragOverAnimationCapture"), f.captureAnimationState(), f !== h && h.captureAnimationState();
      }

      function N(t) {
        return M("dragOverCompleted", {
          insertion: t
        }), t && (u ? c._hideClone() : c._showClone(f), f !== h && (k(z, it ? it.options.ghostClass : c.options.ghostClass, !1), k(z, e.ghostClass, !0)), it !== f && f !== Rt.active ? it = f : f === Rt.active && it && (it = null), h === f && (f._ignoreWhileAnimating = s), f.animateAll(function () {
          M("dragOverAnimationComplete"), f._ignoreWhileAnimating = null;
        }), f !== h && (h.animateAll(), h._ignoreWhileAnimating = null)), (s === z && !z.animated || s === l && !s.animated) && (ht = null), e.dragoverBubble || n.rootEl || s === document || (z.parentNode[j]._isOutsideThisEl(n.target), t || Pt(n)), !e.dragoverBubble && n.stopPropagation && n.stopPropagation(), p = !0;
      }

      function A() {
        tt = F(z), nt = F(z, e.draggable), W({
          sortable: f,
          name: "change",
          toEl: l,
          newIndex: tt,
          newDraggableIndex: nt,
          originalEvent: n
        });
      }
    },
    _ignoreWhileAnimating: null,
    _offMoveEvents: function () {
      s(document, "mousemove", (this || _global)._onTouchMove), s(document, "touchmove", (this || _global)._onTouchMove), s(document, "pointermove", (this || _global)._onTouchMove), s(document, "dragover", Pt), s(document, "mousemove", Pt), s(document, "touchmove", Pt);
    },
    _offUpEvents: function () {
      var t = (this || _global).el.ownerDocument;
      s(t, "mouseup", (this || _global)._onDrop), s(t, "touchend", (this || _global)._onDrop), s(t, "pointerup", (this || _global)._onDrop), s(t, "touchcancel", (this || _global)._onDrop), s(document, "selectstart", this || _global);
    },
    _onDrop: function (t) {
      var e = (this || _global).el,
          n = (this || _global).options;
      tt = F(z), nt = F(z, n.draggable), K("drop", this || _global, {
        evt: t
      }), G = z && z.parentNode, tt = F(z), nt = F(z, n.draggable), Rt.eventCanceled || (yt = wt = vt = !1, clearInterval((this || _global)._loopId), clearTimeout((this || _global)._dragStartTimer), Lt((this || _global).cloneId), Lt((this || _global)._dragStartId), (this || _global).nativeDraggable && (s(document, "drop", this || _global), s(e, "dragstart", (this || _global)._onDragStart)), this._offMoveEvents(), this._offUpEvents(), u && R(document.body, "user-select", ""), R(z, "transform", ""), t && (dt && (t.cancelable && t.preventDefault(), n.dropBubble || t.stopPropagation()), U && U.parentNode && U.parentNode.removeChild(U), (q === G || it && "clone" !== it.lastPutMode) && Q && Q.parentNode && Q.parentNode.removeChild(Q), z && ((this || _global).nativeDraggable && s(z, "dragend", this || _global), Yt(z), z.style["will-change"] = "", dt && !vt && k(z, it ? it.options.ghostClass : (this || _global).options.ghostClass, !1), k(z, (this || _global).options.chosenClass, !1), W({
        sortable: this || _global,
        name: "unchoose",
        toEl: G,
        newIndex: null,
        newDraggableIndex: null,
        originalEvent: t
      }), q !== G ? (0 <= tt && (W({
        rootEl: G,
        name: "add",
        toEl: G,
        fromEl: q,
        originalEvent: t
      }), W({
        sortable: this || _global,
        name: "remove",
        toEl: G,
        originalEvent: t
      }), W({
        rootEl: G,
        name: "sort",
        toEl: G,
        fromEl: q,
        originalEvent: t
      }), W({
        sortable: this || _global,
        name: "sort",
        toEl: G,
        originalEvent: t
      })), it && it.save()) : tt !== J && 0 <= tt && (W({
        sortable: this || _global,
        name: "update",
        toEl: G,
        originalEvent: t
      }), W({
        sortable: this || _global,
        name: "sort",
        toEl: G,
        originalEvent: t
      })), Rt.active && (null != tt && -1 !== tt || (tt = J, nt = et), W({
        sortable: this || _global,
        name: "end",
        toEl: G,
        originalEvent: t
      }), this.save())))), this._nulling();
    },
    _nulling: function () {
      K("nulling", this || _global), q = z = G = U = V = Q = Z = $ = rt = at = dt = tt = nt = J = et = ht = ft = it = ot = Rt.dragged = Rt.ghost = Rt.clone = Rt.active = null, St.forEach(function (t) {
        t.checked = !0;
      }), St.length = lt = st = 0;
    },
    handleEvent: function (t) {
      switch (t.type) {
        case "drop":
        case "dragend":
          this._onDrop(t);

          break;

        case "dragenter":
        case "dragover":
          z && (this._onDragOver(t), function (t) {
            t.dataTransfer && (t.dataTransfer.dropEffect = "move");
            t.cancelable && t.preventDefault();
          }(t));
          break;

        case "selectstart":
          t.preventDefault();
      }
    },
    toArray: function () {
      for (var t, e = [], n = (this || _global).el.children, o = 0, i = n.length, r = (this || _global).options; o < i; o++) P(t = n[o], r.draggable, (this || _global).el, !1) && e.push(t.getAttribute(r.dataIdAttr) || Ft(t));

      return e;
    },
    sort: function (t, e) {
      var o = {},
          i = (this || _global).el;
      this.toArray().forEach(function (t, e) {
        var n = i.children[e];
        P(n, (this || _global).options.draggable, i, !1) && (o[t] = n);
      }, this || _global), e && this.captureAnimationState(), t.forEach(function (t) {
        o[t] && (i.removeChild(o[t]), i.appendChild(o[t]));
      }), e && this.animateAll();
    },
    save: function () {
      var t = (this || _global).options.store;
      t && t.set && t.set(this || _global);
    },
    closest: function (t, e) {
      return P(t, e || (this || _global).options.draggable, (this || _global).el, !1);
    },
    option: function (t, e) {
      var n = (this || _global).options;
      if (void 0 === e) return n[t];
      var o = O.modifyOption(this || _global, t, e);
      n[t] = void 0 !== o ? o : e, "group" === t && Nt(n);
    },
    destroy: function () {
      K("destroy", this || _global);
      var t = (this || _global).el;
      t[j] = null, s(t, "mousedown", (this || _global)._onTapStart), s(t, "touchstart", (this || _global)._onTapStart), s(t, "pointerdown", (this || _global)._onTapStart), (this || _global).nativeDraggable && (s(t, "dragover", this || _global), s(t, "dragenter", this || _global)), Array.prototype.forEach.call(t.querySelectorAll("[draggable]"), function (t) {
        t.removeAttribute("draggable");
      }), this._onDrop(), this._disableDelayedDragEvents(), bt.splice(bt.indexOf((this || _global).el), 1), (this || _global).el = t = null;
    },
    _hideClone: function () {
      if (!$) {
        if (K("hideClone", this || _global), Rt.eventCanceled) return;
        R(Q, "display", "none"), (this || _global).options.removeCloneOnHide && Q.parentNode && Q.parentNode.removeChild(Q), $ = !0;
      }
    },
    _showClone: function (t) {
      if ("clone" === t.lastPutMode) {
        if ($) {
          if (K("showClone", this || _global), Rt.eventCanceled) return;
          z.parentNode != q || (this || _global).options.group.revertClone ? V ? q.insertBefore(Q, V) : q.appendChild(Q) : q.insertBefore(Q, z), (this || _global).options.group.revertClone && this.animate(z, Q), R(Q, "display", ""), $ = !1;
        }
      } else this._hideClone();
    }
  }, _t && d(document, "touchmove", function (t) {
    (Rt.active || vt) && t.cancelable && t.preventDefault();
  }), Rt.utils = {
    on: d,
    off: s,
    css: R,
    find: g,
    is: function (t, e) {
      return !!P(t, e, t, !1);
    },
    extend: function (t, e) {
      if (t && e) for (var n in e) e.hasOwnProperty(n) && (t[n] = e[n]);
      return t;
    },
    throttle: D,
    closest: P,
    toggleClass: k,
    clone: S,
    index: F,
    nextTick: Ht,
    cancelNextTick: Lt,
    detectDirection: Ot,
    getChild: m
  }, Rt.get = function (t) {
    return t[j];
  }, Rt.mount = function () {
    for (var t = arguments.length, e = new Array(t), n = 0; n < t; n++) e[n] = arguments[n];

    e[0].constructor === Array && (e = e[0]), e.forEach(function (t) {
      if (!t.prototype || !t.prototype.constructor) throw "Sortable: Mounted plugin must be a constructor function, not ".concat({}.toString.call(t));
      t.utils && (Rt.utils = I({}, Rt.utils, t.utils)), O.mount(t);
    });
  }, Rt.create = function (t, e) {
    return new Rt(t, e);
  };
  var jt,
      Kt,
      Wt,
      zt,
      Gt,
      Ut,
      qt = [],
      Vt = !(Rt.version = "1.13.0");

  function Zt() {
    qt.forEach(function (t) {
      clearInterval(t.pid);
    }), qt = [];
  }

  function Qt() {
    clearInterval(Ut);
  }

  function $t(t) {
    var e = t.originalEvent,
        n = t.putSortable,
        o = t.dragEl,
        i = t.activeSortable,
        r = t.dispatchSortableEvent,
        a = t.hideGhostForTarget,
        l = t.unhideGhostForTarget;

    if (e) {
      var s = n || i;
      a();
      var c = e.changedTouches && e.changedTouches.length ? e.changedTouches[0] : e,
          u = document.elementFromPoint(c.clientX, c.clientY);
      l(), s && !s.el.contains(u) && (r("spill"), this.onSpill({
        dragEl: o,
        putSortable: n
      }));
    }
  }

  var Jt,
      te = D(function (n, t, e, o) {
    if (t.scroll) {
      var i,
          r = (n.touches ? n.touches[0] : n).clientX,
          a = (n.touches ? n.touches[0] : n).clientY,
          l = t.scrollSensitivity,
          s = t.scrollSpeed,
          c = A(),
          u = !1;
      Kt !== e && (Kt = e, Zt(), jt = t.scroll, i = t.scrollFn, !0 === jt && (jt = H(e, !0)));
      var d = 0,
          h = jt;

      do {
        var f = h,
            p = X(f),
            g = p.top,
            v = p.bottom,
            m = p.left,
            b = p.right,
            y = p.width,
            w = p.height,
            E = void 0,
            D = void 0,
            S = f.scrollWidth,
            _ = f.scrollHeight,
            C = R(f),
            T = f.scrollLeft,
            x = f.scrollTop;
        D = f === c ? (E = y < S && ("auto" === C.overflowX || "scroll" === C.overflowX || "visible" === C.overflowX), w < _ && ("auto" === C.overflowY || "scroll" === C.overflowY || "visible" === C.overflowY)) : (E = y < S && ("auto" === C.overflowX || "scroll" === C.overflowX), w < _ && ("auto" === C.overflowY || "scroll" === C.overflowY));
        var M = E && (Math.abs(b - r) <= l && T + y < S) - (Math.abs(m - r) <= l && !!T),
            O = D && (Math.abs(v - a) <= l && x + w < _) - (Math.abs(g - a) <= l && !!x);
        if (!qt[d]) for (var N = 0; N <= d; N++) qt[N] || (qt[N] = {});
        qt[d].vx == M && qt[d].vy == O && qt[d].el === f || (qt[d].el = f, qt[d].vx = M, qt[d].vy = O, clearInterval(qt[d].pid), 0 == M && 0 == O || (u = !0, qt[d].pid = setInterval(function () {
          o && 0 === (this || _global).layer && Rt.active._onTouchMove(Gt);
          var t = qt[(this || _global).layer].vy ? qt[(this || _global).layer].vy * s : 0,
              e = qt[(this || _global).layer].vx ? qt[(this || _global).layer].vx * s : 0;
          "function" == typeof i && "continue" !== i.call(Rt.dragged.parentNode[j], e, t, n, Gt, qt[(this || _global).layer].el) || L(qt[(this || _global).layer].el, e, t);
        }.bind({
          layer: d
        }), 24))), d++;
      } while (t.bubbleScroll && h !== c && (h = H(h, !1)));

      Vt = u;
    }
  }, 30);

  function ee() {}

  function ne() {}

  ee.prototype = {
    startIndex: null,
    dragStart: function (t) {
      var e = t.oldDraggableIndex;
      (this || _global).startIndex = e;
    },
    onSpill: function (t) {
      var e = t.dragEl,
          n = t.putSortable;
      (this || _global).sortable.captureAnimationState(), n && n.captureAnimationState();
      var o = m((this || _global).sortable.el, (this || _global).startIndex, (this || _global).options);
      o ? (this || _global).sortable.el.insertBefore(e, o) : (this || _global).sortable.el.appendChild(e), (this || _global).sortable.animateAll(), n && n.animateAll();
    },
    drop: $t
  }, a(ee, {
    pluginName: "revertOnSpill"
  }), ne.prototype = {
    onSpill: function (t) {
      var e = t.dragEl,
          n = t.putSortable || (this || _global).sortable;
      n.captureAnimationState(), e.parentNode && e.parentNode.removeChild(e), n.animateAll();
    },
    drop: $t
  }, a(ne, {
    pluginName: "removeOnSpill"
  });
  var oe,
      ie,
      re,
      ae,
      le,
      se = [],
      ce = [],
      ue = !1,
      de = !1,
      he = !1;

  function fe(o, i) {
    ce.forEach(function (t, e) {
      var n = i.children[t.sortableIndex + (o ? Number(e) : 0)];
      n ? i.insertBefore(t, n) : i.appendChild(t);
    });
  }

  function pe() {
    se.forEach(function (t) {
      t !== re && t.parentNode && t.parentNode.removeChild(t);
    });
  }

  return Rt.mount(new function () {
    function t() {
      for (var t in (this || _global).defaults = {
        scroll: !0,
        scrollSensitivity: 30,
        scrollSpeed: 10,
        bubbleScroll: !0
      }, this || _global) "_" === t.charAt(0) && "function" == typeof (this || _global)[t] && ((this || _global)[t] = (this || _global)[t].bind(this || _global));
    }

    return t.prototype = {
      dragStarted: function (t) {
        var e = t.originalEvent;
        (this || _global).sortable.nativeDraggable ? d(document, "dragover", (this || _global)._handleAutoScroll) : (this || _global).options.supportPointer ? d(document, "pointermove", (this || _global)._handleFallbackAutoScroll) : e.touches ? d(document, "touchmove", (this || _global)._handleFallbackAutoScroll) : d(document, "mousemove", (this || _global)._handleFallbackAutoScroll);
      },
      dragOverCompleted: function (t) {
        var e = t.originalEvent;
        (this || _global).options.dragOverBubble || e.rootEl || this._handleAutoScroll(e);
      },
      drop: function () {
        (this || _global).sortable.nativeDraggable ? s(document, "dragover", (this || _global)._handleAutoScroll) : (s(document, "pointermove", (this || _global)._handleFallbackAutoScroll), s(document, "touchmove", (this || _global)._handleFallbackAutoScroll), s(document, "mousemove", (this || _global)._handleFallbackAutoScroll)), Qt(), Zt(), clearTimeout(f), f = void 0;
      },
      nulling: function () {
        Gt = Kt = jt = Vt = Ut = Wt = zt = null, qt.length = 0;
      },
      _handleFallbackAutoScroll: function (t) {
        this._handleAutoScroll(t, !0);
      },
      _handleAutoScroll: function (e, n) {
        var o = this || _global,
            i = (e.touches ? e.touches[0] : e).clientX,
            r = (e.touches ? e.touches[0] : e).clientY,
            t = document.elementFromPoint(i, r);

        if (Gt = e, n || E || w || u) {
          te(e, (this || _global).options, t, n);
          var a = H(t, !0);
          !Vt || Ut && i === Wt && r === zt || (Ut && Qt(), Ut = setInterval(function () {
            var t = H(document.elementFromPoint(i, r), !0);
            t !== a && (a = t, Zt()), te(e, o.options, t, n);
          }, 10), Wt = i, zt = r);
        } else {
          if (!(this || _global).options.bubbleScroll || H(t, !0) === A()) return void Zt();
          te(e, (this || _global).options, H(t, !1), !1);
        }
      }
    }, a(t, {
      pluginName: "scroll",
      initializeByDefault: !0
    });
  }()), Rt.mount(ne, ee), Rt.mount(new function () {
    function t() {
      (this || _global).defaults = {
        swapClass: "sortable-swap-highlight"
      };
    }

    return t.prototype = {
      dragStart: function (t) {
        var e = t.dragEl;
        Jt = e;
      },
      dragOverValid: function (t) {
        var e = t.completed,
            n = t.target,
            o = t.onMove,
            i = t.activeSortable,
            r = t.changed,
            a = t.cancel;

        if (i.options.swap) {
          var l = (this || _global).sortable.el,
              s = (this || _global).options;

          if (n && n !== l) {
            var c = Jt;
            Jt = !1 !== o(n) ? (k(n, s.swapClass, !0), n) : null, c && c !== Jt && k(c, s.swapClass, !1);
          }

          r(), e(!0), a();
        }
      },
      drop: function (t) {
        var e = t.activeSortable,
            n = t.putSortable,
            o = t.dragEl,
            i = n || (this || _global).sortable,
            r = (this || _global).options;
        Jt && k(Jt, r.swapClass, !1), Jt && (r.swap || n && n.options.swap) && o !== Jt && (i.captureAnimationState(), i !== e && e.captureAnimationState(), function (t, e) {
          var n,
              o,
              i = t.parentNode,
              r = e.parentNode;
          if (!i || !r || i.isEqualNode(e) || r.isEqualNode(t)) return;
          n = F(t), o = F(e), i.isEqualNode(r) && n < o && o++;
          i.insertBefore(e, i.children[n]), r.insertBefore(t, r.children[o]);
        }(o, Jt), i.animateAll(), i !== e && e.animateAll());
      },
      nulling: function () {
        Jt = null;
      }
    }, a(t, {
      pluginName: "swap",
      eventProperties: function () {
        return {
          swapItem: Jt
        };
      }
    });
  }()), Rt.mount(new function () {
    function t(o) {
      for (var t in this || _global) "_" === t.charAt(0) && "function" == typeof (this || _global)[t] && ((this || _global)[t] = (this || _global)[t].bind(this || _global));

      o.options.supportPointer ? d(document, "pointerup", (this || _global)._deselectMultiDrag) : (d(document, "mouseup", (this || _global)._deselectMultiDrag), d(document, "touchend", (this || _global)._deselectMultiDrag)), d(document, "keydown", (this || _global)._checkKeyDown), d(document, "keyup", (this || _global)._checkKeyUp), (this || _global).defaults = {
        selectedClass: "sortable-selected",
        multiDragKey: null,
        setData: function (t, e) {
          var n = "";
          se.length && ie === o ? se.forEach(function (t, e) {
            n += (e ? ", " : "") + t.textContent;
          }) : n = e.textContent, t.setData("Text", n);
        }
      };
    }

    return t.prototype = {
      multiDragKeyDown: !1,
      isMultiDrag: !1,
      delayStartGlobal: function (t) {
        var e = t.dragEl;
        re = e;
      },
      delayEnded: function () {
        (this || _global).isMultiDrag = ~se.indexOf(re);
      },
      setupClone: function (t) {
        var e = t.sortable,
            n = t.cancel;

        if ((this || _global).isMultiDrag) {
          for (var o = 0; o < se.length; o++) ce.push(S(se[o])), ce[o].sortableIndex = se[o].sortableIndex, ce[o].draggable = !1, ce[o].style["will-change"] = "", k(ce[o], (this || _global).options.selectedClass, !1), se[o] === re && k(ce[o], (this || _global).options.chosenClass, !1);

          e._hideClone(), n();
        }
      },
      clone: function (t) {
        var e = t.sortable,
            n = t.rootEl,
            o = t.dispatchSortableEvent,
            i = t.cancel;
        (this || _global).isMultiDrag && ((this || _global).options.removeCloneOnHide || se.length && ie === e && (fe(!0, n), o("clone"), i()));
      },
      showClone: function (t) {
        var e = t.cloneNowShown,
            n = t.rootEl,
            o = t.cancel;
        (this || _global).isMultiDrag && (fe(!1, n), ce.forEach(function (t) {
          R(t, "display", "");
        }), e(), le = !1, o());
      },
      hideClone: function (t) {
        var e = this || _global,
            n = (t.sortable, t.cloneNowHidden),
            o = t.cancel;
        (this || _global).isMultiDrag && (ce.forEach(function (t) {
          R(t, "display", "none"), e.options.removeCloneOnHide && t.parentNode && t.parentNode.removeChild(t);
        }), n(), le = !0, o());
      },
      dragStartGlobal: function (t) {
        t.sortable;
        !(this || _global).isMultiDrag && ie && ie.multiDrag._deselectMultiDrag(), se.forEach(function (t) {
          t.sortableIndex = F(t);
        }), se = se.sort(function (t, e) {
          return t.sortableIndex - e.sortableIndex;
        }), he = !0;
      },
      dragStarted: function (t) {
        var e = this || _global,
            n = t.sortable;

        if ((this || _global).isMultiDrag) {
          if ((this || _global).options.sort && (n.captureAnimationState(), (this || _global).options.animation)) {
            se.forEach(function (t) {
              t !== re && R(t, "position", "absolute");
            });
            var o = X(re, !1, !0, !0);
            se.forEach(function (t) {
              t !== re && _(t, o);
            }), ue = de = !0;
          }

          n.animateAll(function () {
            ue = de = !1, e.options.animation && se.forEach(function (t) {
              C(t);
            }), e.options.sort && pe();
          });
        }
      },
      dragOver: function (t) {
        var e = t.target,
            n = t.completed,
            o = t.cancel;
        de && ~se.indexOf(e) && (n(!1), o());
      },
      revert: function (t) {
        var e = t.fromSortable,
            n = t.rootEl,
            o = t.sortable,
            i = t.dragRect;
        1 < se.length && (se.forEach(function (t) {
          o.addAnimationState({
            target: t,
            rect: de ? X(t) : i
          }), C(t), t.fromRect = i, e.removeAnimationState(t);
        }), de = !1, function (o, i) {
          se.forEach(function (t, e) {
            var n = i.children[t.sortableIndex + (o ? Number(e) : 0)];
            n ? i.insertBefore(t, n) : i.appendChild(t);
          });
        }(!(this || _global).options.removeCloneOnHide, n));
      },
      dragOverCompleted: function (t) {
        var e = t.sortable,
            n = t.isOwner,
            o = t.insertion,
            i = t.activeSortable,
            r = t.parentEl,
            a = t.putSortable,
            l = (this || _global).options;

        if (o) {
          if (n && i._hideClone(), ue = !1, l.animation && 1 < se.length && (de || !n && !i.options.sort && !a)) {
            var s = X(re, !1, !0, !0);
            se.forEach(function (t) {
              t !== re && (_(t, s), r.appendChild(t));
            }), de = !0;
          }

          if (!n) if (de || pe(), 1 < se.length) {
            var c = le;
            i._showClone(e), i.options.animation && !le && c && ce.forEach(function (t) {
              i.addAnimationState({
                target: t,
                rect: ae
              }), t.fromRect = ae, t.thisAnimationDuration = null;
            });
          } else i._showClone(e);
        }
      },
      dragOverAnimationCapture: function (t) {
        var e = t.dragRect,
            n = t.isOwner,
            o = t.activeSortable;

        if (se.forEach(function (t) {
          t.thisAnimationDuration = null;
        }), o.options.animation && !n && o.multiDrag.isMultiDrag) {
          ae = a({}, e);
          var i = v(re, !0);
          ae.top -= i.f, ae.left -= i.e;
        }
      },
      dragOverAnimationComplete: function () {
        de && (de = !1, pe());
      },
      drop: function (t) {
        var e = t.originalEvent,
            n = t.rootEl,
            o = t.parentEl,
            i = t.sortable,
            r = t.dispatchSortableEvent,
            a = t.oldIndex,
            l = t.putSortable,
            s = l || (this || _global).sortable;

        if (e) {
          var c = (this || _global).options,
              u = o.children;
          if (!he) if (c.multiDragKey && !(this || _global).multiDragKeyDown && this._deselectMultiDrag(), k(re, c.selectedClass, !~se.indexOf(re)), ~se.indexOf(re)) se.splice(se.indexOf(re), 1), oe = null, N({
            sortable: i,
            rootEl: n,
            name: "deselect",
            targetEl: re,
            originalEvt: e
          });else {
            if (se.push(re), N({
              sortable: i,
              rootEl: n,
              name: "select",
              targetEl: re,
              originalEvt: e
            }), e.shiftKey && oe && i.el.contains(oe)) {
              var d,
                  h,
                  f = F(oe),
                  p = F(re);
              if (~f && ~p && f !== p) for (d = f < p ? (h = f, p) : (h = p, f + 1); h < d; h++) ~se.indexOf(u[h]) || (k(u[h], c.selectedClass, !0), se.push(u[h]), N({
                sortable: i,
                rootEl: n,
                name: "select",
                targetEl: u[h],
                originalEvt: e
              }));
            } else oe = re;

            ie = s;
          }

          if (he && (this || _global).isMultiDrag) {
            if ((o[j].options.sort || o !== n) && 1 < se.length) {
              var g = X(re),
                  v = F(re, ":not(." + (this || _global).options.selectedClass + ")");

              if (!ue && c.animation && (re.thisAnimationDuration = null), s.captureAnimationState(), !ue && (c.animation && (re.fromRect = g, se.forEach(function (t) {
                if (t.thisAnimationDuration = null, t !== re) {
                  var e = de ? X(t) : g;
                  t.fromRect = e, s.addAnimationState({
                    target: t,
                    rect: e
                  });
                }
              })), pe(), se.forEach(function (t) {
                u[v] ? o.insertBefore(t, u[v]) : o.appendChild(t), v++;
              }), a === F(re))) {
                var m = !1;
                se.forEach(function (t) {
                  t.sortableIndex === F(t) || (m = !0);
                }), m && r("update");
              }

              se.forEach(function (t) {
                C(t);
              }), s.animateAll();
            }

            ie = s;
          }

          (n === o || l && "clone" !== l.lastPutMode) && ce.forEach(function (t) {
            t.parentNode && t.parentNode.removeChild(t);
          });
        }
      },
      nullingGlobal: function () {
        (this || _global).isMultiDrag = he = !1, ce.length = 0;
      },
      destroyGlobal: function () {
        this._deselectMultiDrag(), s(document, "pointerup", (this || _global)._deselectMultiDrag), s(document, "mouseup", (this || _global)._deselectMultiDrag), s(document, "touchend", (this || _global)._deselectMultiDrag), s(document, "keydown", (this || _global)._checkKeyDown), s(document, "keyup", (this || _global)._checkKeyUp);
      },
      _deselectMultiDrag: function (t) {
        if (!(void 0 !== he && he || ie !== (this || _global).sortable || t && P(t.target, (this || _global).options.draggable, (this || _global).sortable.el, !1) || t && 0 !== t.button)) for (; se.length;) {
          var e = se[0];
          k(e, (this || _global).options.selectedClass, !1), se.shift(), N({
            sortable: (this || _global).sortable,
            rootEl: (this || _global).sortable.el,
            name: "deselect",
            targetEl: e,
            originalEvt: t
          });
        }
      },
      _checkKeyDown: function (t) {
        t.key === (this || _global).options.multiDragKey && ((this || _global).multiDragKeyDown = !0);
      },
      _checkKeyUp: function (t) {
        t.key === (this || _global).options.multiDragKey && ((this || _global).multiDragKeyDown = !1);
      }
    }, a(t, {
      pluginName: "multiDrag",
      utils: {
        select: function (t) {
          var e = t.parentNode[j];
          e && e.options.multiDrag && !~se.indexOf(t) && (ie && ie !== e && (ie.multiDrag._deselectMultiDrag(), ie = e), k(t, e.options.selectedClass, !0), se.push(t));
        },
        deselect: function (t) {
          var e = t.parentNode[j],
              n = se.indexOf(t);
          e && e.options.multiDrag && ~n && (k(t, e.options.selectedClass, !1), se.splice(n, 1));
        }
      },
      eventProperties: function () {
        var n = this || _global,
            o = [],
            i = [];
        return se.forEach(function (t) {
          var e;
          o.push({
            multiDragElement: t,
            index: t.sortableIndex
          }), e = de && t !== re ? -1 : de ? F(t, ":not(." + n.options.selectedClass + ")") : F(t), i.push({
            multiDragElement: t,
            index: e
          });
        }), {
          items: e(se),
          clones: [].concat(ce),
          oldIndicies: o,
          newIndicies: i
        };
      },
      optionListeners: {
        multiDragKey: function (t) {
          return "ctrl" === (t = t.toLowerCase()) ? t = "Control" : 1 < t.length && (t = t.charAt(0).toUpperCase() + t.substr(1)), t;
        }
      }
    });
  }()), Rt;
});
export default exports;